<template>
  <vuestic-modal
    v-bind:large="true"
    ref="modal"
    v-on:ok="cancelarRelato"
    :okClass="classe"
  >
    <div slot="title">Descrição do relato cancelado</div>

    <div class="container">
      <div class="row">
        <div class="col">
          <div class="form-group">

            <div class="input-group">
              <textarea
                id="simple-input"
                required
                v-model="comentario"
                :disabled="comentarioFinalizacao !== ''"
              />
              <label
                class="select-control-label"
                for="simple-input"
              >Descrição:
              </label>
            </div>
          </div>
        </div>
      </div>
    </div>

  </vuestic-modal>
</template>

<script>
export default {
  props: {
    comentarioFinalizacao: {
      type: String,
      default: '',
    },
  },
  data() {
    return {
      comentario: '',
      classe: 'btn-micro btn btn-primary',
    };
  },
  methods: {
    open() {
      this.$refs.modal.open();
    },
    cancelarRelato() {
      if (this.comentario) {
        this.$emit('cancelarRelato', this.comentario.trim());
      }
      this.comentario = '';
    },
  },
  watch: {
    comentarioFinalizacao() {
      this.classe = this.comentarioFinalizacao ? 'none' : 'btn-micro btn btn-primary';
      this.comentario = this.comentarioFinalizacao;
    },
  },
};
</script>

<style>
</style>
