<template>
  <vuestic-modal
    v-bind:large="true"
    ref="largeModalEdict"
    okClass="none"
    cancelClass="none"
  >

    <div slot="title">{{situacao.nome[relato.situacao]}}</div>

    <p class="style-box">Dados do Relatante</p>
    <div class="container">
      <div class="row">
        <div class="col">
          <div class="form-group">
            <label class="select-control-label">Relatante</label>
            <span class="box pr-5 pl-2">
              {{relatoParaEditar.nome_do_relatante || 'Anônimo'}}
            </span>
          </div>
        </div>
        <div class="col">
          <div class="form-group">
            <label class="select-control-label">Áreas do Relatante</label>
            <span class="box pr-5 pl-2">
              {{relatoParaEditar.area_relatante_nome || 'Anônima'}}
            </span>
          </div>
        </div>
      </div>
    </div>
    <br>

    <p class="style-box">Dados do Relato</p>
    <div class="container">
      <div class="row">
        <div class="col">
          <div class="form-group">
            <div class="input-group">
              <label class="select-control-label style-label-top--1">Itens</label>
              <div>
                <el-button
                  class="style-box-item"
                  type="info"
                  plain
                  @click="$refs.largeModalEdictIten.open()"
                >{{relatoParaEditar.item_nome}}</el-button>
              </div>
            </div>
          </div>
        </div>
        <div class="col">
          <div class="form-group">
            <label class="select-control-label">Situação</label>
            <multiselect
              v-model="status"
              placeholder="Selecione"
              track-by="id"
              label="nome"
              :options="situacao.array"
              selectedLabel="Selecionado"
              select-label="Selecione um status"
              deselect-label="Remover status"
            >
              <span slot="noResult">Status não encontrado.</span>
              <template slot="noOptions">Sem Status</template>
            </multiselect>
          </div>
        </div>
        <div class="col">
          <div class="form-group">
            <label class="select-control-label">ÁREA DO RELATADO</label>
            <multiselect
              v-model="area_do_relatado"
              placeholder="Selecione"
              track-by="id"
              label="nome"
              :options="areas"
              selectedLabel="Selecionado"
              select-label="Selecione uma área"
              deselect-label="Remover área"
            >
              <span slot="noResult">Área não encontrado.</span>
              <template slot="noOptions">Sem Áreas</template>
            </multiselect>
          </div>
        </div>
      </div>

      <div class="row">
        <div class="col">
          <div class="form-group">
            <div class="input-group">
              <input
                v-model="relatoParaEditar.local"
                id="simple-input"
                required
              />
              <label
                class="control-label"
                for="simple-input"
              >Local:
              </label>
            </div>
          </div>
        </div>
        <div class="col">
          <div class="form-group">
            <div class="input-group">
              <input
                v-model="relatoParaEditar.placa"
                id="simple-input"
                required
              />
              <label
                class="control-label"
                for="simple-input"
              >Placa:
              </label>
            </div>
          </div>

        </div>
        <div class="col">
          <div class="form-group">
            <div class="input-group">
              <input
                type="datetime-local"
                v-model="relatoParaEditar.hora"
                id="simple-input"
                required
              >
              <label
                class="select-control-label"
                for="simple-input"
              >Data e Hora</label>

            </div>
          </div>

        </div>
      </div>

      <div class="row">
        <div class="col">
          <div class="form-group">
            <div class="input-group">
              <input
                v-model="relatoParaEditar.funcionario"
                id="simple-input"
                required
              />
              <label
                class="control-label"
                for="simple-input"
              >Funcionário:
              </label>
            </div>
          </div>
        </div>
        <div class="col">
          <div class="form-group">
            <div class="input-group">
              <input
                v-model="relatoParaEditar.funcao"
                id="simple-input"
                required
              />
              <label
                class="control-label"
                for="simple-input"
              >Função:
              </label>
            </div>
          </div>
        </div>
      </div>

      <div class="row">
        <div class="col">
          <div class="form-group">
            <div class="input-group">
              <textarea
                v-model="relatoParaEditar.descricao"
                id="simple-input"
                required
              />
              <label
                class="control-label"
                for="simple-input"
              >Descrição:
              </label>
            </div>
          </div>
        </div>
      </div>
      <div class="row d-flex justify-content-center">
        <button class="btn btn-info btn-micro mr-3" @click="openAcao">
        Abrir Ação
        </button>
        <button
          align="center"
          class="btn btn-primary btn-micro"
          type="button"
          @click="$refs.staticModalEditar.open()"
        >Salvar Alterações</button>
      </div>

      <!---->

      <vuestic-modal
        ref="staticModalEditar"
        v-on:ok="editarRelato()"
      >
        <div slot="title">{{'Editar' | translate}}</div>
        <div>Você deseja editar o Relato?</div>
      </vuestic-modal>
    </div>
    <modal-visualizar-relato
      ref="largeModalEdictIten"
      :relato="relato"
      :itemRelato="itemRelato"
      :grupoRelato="grupoRelato"
      :tiposRelatos="tiposRelatos"
      v-on:mudarItem="mudarItem"
    />
    <modal-cancelar-relato
      ref="modalCancelarRelato"
      :comentarioFinalizacao="relato.comentario_finalização"
      v-on:cancelarRelato="cancelarRelato"
    />
    <modal-tratar-relato
      ref="modalTratarRelato"
      :comentarioTratado="relato.comentario"
      v-on:tratarRelato="tratarRelato"
    />
    <modal-lancar-acao-de-relato
     ref="modalLancarAcaoDeRelato"
     v-on:success="tratarRelato(relatoParaEditar.comentario)"
     :acao="acao"/>
  </vuestic-modal>
</template>

<script>
import modalVisualizarRelato from './modalEditarItem.vue';
import modalCancelarRelato from './modalCancelarRelato.vue';
import modalTratarRelato from './modalTratarRelato.vue';
import modalLancarAcaoDeRelato from './modalLancarAcaoDeRelato.vue';


export default {
  components: {
    modalVisualizarRelato, modalCancelarRelato, modalTratarRelato, modalLancarAcaoDeRelato,
  },
  props: {
    relato: {
      type: Object,
      required: true,
    },
    areas: {
      type: Array,
      default: () => [],
    },
    itemRelato: {
      type: Array,
      default: () => [],
    },
    grupoRelato: {
      type: Array,
      default: () => [],
    },
    tiposRelatos: {
      type: Array,
      default: () => [],
    },
  },
  data() {
    return {
      acao: {
        area: null,
        ferramenta: {
          id: 66,
          ferramenta: 'Relato de Segurança',
        },
      },
      status: {},
      relatoParaEditar: null,
      area_do_relatante: null,
      area_do_relatado: null,
      situacao: {
        array: [
          { id: 1, nome: 'Relato Pendente' },
          { id: 2, nome: 'Relato Cancelado' },
          { id: 3, nome: 'Relato Tratado' }],
        nome: {
          1: 'Relato Pendente',
          2: 'Relato Cancelado',
          3: 'Relato Tratado',
        },
        tipo: {
          1: 'danger',
          2: 'info',
          3: 'success',
        },
      },
    };
  },
  created() {
    this.relatoParaEditar = this.copy(this.relato);
  },
  methods: {
    open() {
      this.$refs.largeModalEdict.open();
      this.prepara(this.relato);
    },
    cancelarRelato(comentario) {
      this.relatoParaEditar.comentario_finalização = comentario;
      this.relatoParaEditar.situacao = 2;
      this.editarRelato();
    },
    openAcao() {
      this.acao.relato = [this.relato.id];
      this.acao.area = this.area_do_relatado;
      this.$refs.modalLancarAcaoDeRelato.open();
    },
    tratarRelato(comentario) {
      this.relatoParaEditar.comentario = comentario;
      this.relatoParaEditar.situacao = 3;
      this.editarRelato();
    },
    copy(element = {}) {
      return JSON.parse(JSON.stringify(element));
    },
    prepara(valor) {
      this.status = this.copy(this.situacao.array[valor.situacao - 1]);
      this.relatoParaEditar = this.copy(valor);
      delete this.relatoParaEditar.imagem;
    },
    message(type, title) {
      this.$swal({
        toast: true,
        position: 'top-end',
        showCancelButton: false,
        type,
        title,
        showConfirmButton: false,
        timer: 2000,
      });
    },
    editarRelato() {
      this.$swal({
        title: 'Preparando a Exportação',
      });
      this.$swal.showLoading();
      if (this.relatoParaEditar.situacao !== 2) {
        this.relatoParaEditar.comentario_finalização = '';
      }
      if (this.relatoParaEditar.situacao !== 3) {
        this.relatoParaEditar.comentario = '';
      }
      this.$axios.put(`/api/relato/${this.relatoParaEditar.id}/`,
        this.relatoParaEditar).then(() => {
        this.$swal.close();
        this.message('success', 'Editado com Sucesso!');
        this.$emit('editado');
        this.$refs.largeModalEdict.close();
      }).catch(() => {
        this.message('error', 'Erro ao Editar');
      });
    },
    mudarItem(evento) {
      this.relatoParaEditar.item = evento.id;
      this.relatoParaEditar.item_nome = evento.nome;
    },
  },
  watch: {
    area_do_relatante() {
      this.relatoParaEditar.area_do_relatante = this.area_do_relatante
        ? this.area_do_relatante.id : null;
    },
    area_do_relatado() {
      this.relatoParaEditar.area_do_relatado = this.area_do_relatado
        ? this.area_do_relatado.id : null;
    },
    relato(valor) {
      this.prepara(valor);
      this.area_do_relatante = this.areas.find(a => a.id === valor.area_do_relatante);
      this.area_do_relatado = this.areas.find(a => a.id === valor.area_do_relatado);
    },
    status(valor) {
      if (valor.id === 2) {
        this.$refs.modalCancelarRelato.open();
        this.status = this.situacao.array[this.relatoParaEditar.situacao - 1];
      } else if (valor.id === 3) {
        this.$refs.modalTratarRelato.open();
        this.status = this.situacao.array[this.relatoParaEditar.situacao - 1];
      } else {
        this.relatoParaEditar.situacao = valor.id;
      }
    },
  },
};
</script>

<style scoped>
.box {
  padding: 0.125rem 0.125rem 0.0625rem;
  border: 1px solid #ccc;
  border-radius: 5px;
  min-width: 90%;
}
.el-date-editor.el-input,
.el-date-editor.el-input__inner {
  width: 100% !important;
}
.style-label-top--1 {
  top: -1rem !important;
}
.style-box-item {
  width: 100% !important;
  padding: 12px 20px !important;
}
</style>
